
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { mainSocketFollow } from '@/socket';

import CommonSidebar from '@/components/common/sidebar/index.vue';
import CommonMobileSidebar from '@/components/common/sidebar/mobile/index.vue';
import CommonDropElement from '@/components/common/drop/DropElement.vue';
import CommonHeader from '@/components/common/header/index.vue';
import CommonModal from '@/components/common/modal/index.vue';
import CommonCalendar from '@/components/common/calendar/index.vue';
import GeneralNotification from '@/components/common/notification/General.vue';
import { initialChart } from "@/store/common/chart";

export default defineComponent({
  name: "owner-layout",
  props: ['empty'],
  async created() {
    if (this.$route.meta.requiredAuth)
      mainSocketFollow(this);
  },
  mounted() {
    initialChart();
    this.onEmits();
  },
  unmounted() {
    this.offEmits();
  },
  methods: {
    // Emitter
    onEmits() {
      this.emitter.on("user-logout", this.userLogoutEmitter);
    },
    offEmits() {
      this.emitter.off("user-logout", this.userLogoutEmitter);
    },
    userLogoutEmitter() {
      this.$store.dispatch("logoutAction");
    },
  },
  computed: {
    ...mapGetters(["user", "modal", "drop", "calendar", 'innerWidth']),
    isLayout() {
      return !!this.$route.meta.layout;
    },
    isDesktop() {
      return this.innerWidth > 740
    },
    layout() {
      return this.$route.meta.layout + "-layout";
    },
  },
  components: {
    CommonSidebar,
    CommonMobileSidebar,
    CommonHeader,
    CommonModal,
    CommonDropElement,
    CommonCalendar,
    GeneralNotification
  }
});
